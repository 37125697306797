import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "office"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/office.PNG"}) { ...eyecatchImg },
    word_s: file(relativePath: { eq: "office/word_s.png"}) { ...smallImg },
    excel_s: file(relativePath: { eq: "office/excel_s.png"}) { ...smallImg },
    microsoft_google: file(relativePath: { eq: "office/microsoft_google.png"}) { ...normalImg },
    compati: file(relativePath: { eq: "office/compati.png"}) { ...normalImg },
    soneki: file(relativePath: { eq: "office/soneki.png"}) { ...normalImg },
    wondering: file(relativePath: { eq: "office/wondering.png"}) { ...normalImg },
    powerpoint_s: file(relativePath: { eq: "office/power-point_s.png"}) { ...smallImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`オフィスソフト（Microsoft Office)は必要か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="マイクロソフトオフィスは必要なのか？" mdxType="Image" />
    <p>{`Word、Excel、PowerPointに代表されるMicrosoft Officeはパソコンの基本ソフトであるが、非常に高価で新品のパソコンに搭載する場合、２万円から３万円程度本体価格から上乗せとなる。`}</p>
    <p>{`ところが必要だと感じて後からパッケージソフトを購入すると、最低購入金額がパソコンにバンドルされる時よりも上がってしまうため、後回しにすることもしにくい製品である。`}</p>
    <p>{`そこで本ページでは無料のGoogle Docsなどがある時代にどのような人がオフィスを入れておくと良いか？について説明していく。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "日常におけるオフィスソフトの使用シーン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%97%A5%E5%B8%B8%E3%81%AB%E3%81%8A%E3%81%91%E3%82%8B%E3%82%AA%E3%83%95%E3%82%A3%E3%82%B9%E3%82%BD%E3%83%95%E3%83%88%E3%81%AE%E4%BD%BF%E7%94%A8%E3%82%B7%E3%83%BC%E3%83%B3",
        "aria-label": "日常におけるオフィスソフトの使用シーン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`日常におけるオフィスソフトの使用シーン`}</h2>
    <p>{`Wordは文書作成ツール、エクセルは表計算で家計簿、学習管理などをするツール、パワーポイントはプレゼンテーションツールと何となく理解している人が多いだろう。
しかし、意外な使われ方も多くあるので、まずはどのようなシーンで使われているかということを確認していきたい。`}</p>
    <h3 {...{
      "id": "Wordは年賀状、名刺、POP、地図入り案内状",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Word%E3%81%AF%E5%B9%B4%E8%B3%80%E7%8A%B6%E3%80%81%E5%90%8D%E5%88%BA%E3%80%81POP%E3%80%81%E5%9C%B0%E5%9B%B3%E5%85%A5%E3%82%8A%E6%A1%88%E5%86%85%E7%8A%B6",
        "aria-label": "Wordは年賀状、名刺、POP、地図入り案内状 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wordは年賀状、名刺、POP、地図入り案内状`}</h3>
    <Image {...props} name="word_s" alt="パワーポイント" mdxType="Image" />
    <p>{`Wordはただ文書を書くだけのツールではない。`}<strong parentName="p">{`年賀状や名刺、店頭用のPOP、地図入りの案内状など他にも多くの用途で使うことができる`}</strong>{`。`}</p>
    <p>{`たとえば、年賀状の場合、Wordのオプションではがきサイズを指定し、空いたスペースに様々な書体の文字やイラストを組み合わせて配置することで仕上げることができる。`}</p>
    <p>{`Wordファイルが完成したのち印刷してやれば完成である。印刷は自宅にプリンターがあれば問題がないが、コンビニでも対応することができる。`}</p>
    <h3 {...{
      "id": "Excelは家計簿、リスト管理",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Excel%E3%81%AF%E5%AE%B6%E8%A8%88%E7%B0%BF%E3%80%81%E3%83%AA%E3%82%B9%E3%83%88%E7%AE%A1%E7%90%86",
        "aria-label": "Excelは家計簿、リスト管理 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Excelは家計簿、リスト管理`}</h3>
    <Image {...props} name="excel_s" alt="エクセル" mdxType="Image" />
    <p>{`Excelで日常使うことの代表が家計簿である。毎日の収支を入力し、月、年の合計を簡単に計算することができる。また収支の推移をグラフで表示することも簡単にできる。`}</p>
    <p>{`さらに機能を使いこなすことができると、毎年の収支から次の年の収支を予測、保険金のシミュレーション、株の利益が毎年3％と仮定した時の資産の推移など、お金に関する様々なシミュレーションをおこなうことができる。`}<strong parentName="p">{`高機能な家計分析ツールとして日常生活に役立つ`}</strong>{`わけである。`}</p>
    <p>{`しかし表計算をおこなうばかりがExcelの機能ではなく、`}<strong parentName="p">{`リストとしての使い勝手も良い`}</strong>{`。たとえばイベントの参加者とその住所一覧の管理、学習計画リストの作成、その他計画の進捗管理目的などが多く考えられる。`}</p>
    <h3 {...{
      "id": "パワーポイントで旅行のしおり、動画も",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%83%AF%E3%83%BC%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88%E3%81%A7%E6%97%85%E8%A1%8C%E3%81%AE%E3%81%97%E3%81%8A%E3%82%8A%E3%80%81%E5%8B%95%E7%94%BB%E3%82%82",
        "aria-label": "パワーポイントで旅行のしおり、動画も permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パワーポイントで旅行のしおり、動画も`}</h3>
    <Image {...props} name="powerpoint_s" alt="パワーポイント" mdxType="Image" />
    <p>{`パワーポイントに関しても用途はプレゼンテーションのみではない。パワーポイントは、図形や写真、文字を様々に組み合わせてスライド(パワーポイントの一枚の画面のこと)をつくることを得意としており、旅行のしおり、簡単なスライドショー動画などにも使うことができる。`}</p>
    <p>{`旅行のしおりの場合、インターネットから写真をペタペタと貼り付けて、スケジュールや持ち物チェックリストを入力すれば完成である。`}</p>
    <p>{`また複数の`}<strong parentName="p">{`スライドを動画として出力することができる`}</strong>{`。結婚式で流れるような、写真が数秒ごとに切り替わる、メッセージ付きスライドショー動画をつくることは簡単である。`}</p>
    <h2 {...{
      "id": "GoogleのOfficeで十分ではないか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Google%E3%81%AEOffice%E3%81%A7%E5%8D%81%E5%88%86%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84%E3%81%8B%EF%BC%9F",
        "aria-label": "GoogleのOfficeで十分ではないか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`GoogleのOfficeで十分ではないか？`}</h2>
    <Image {...props} name="microsoft_google" alt="Google Officeで十分か？" mdxType="Image" />
    <p>{`GoogleもOfficeソフトを提供しており、Word、Excel、PowerPointの代わりに、`}<em parentName="p">{`Google Docs`}</em>{`、`}<em parentName="p">{`Google SpreadSheet`}</em>{`、`}<em parentName="p">{`Google Slide`}</em>{`というオンラインの製品をGoogleアカウントさえ作れば無料で使うことができる。`}</p>
    <p>{`よって状況によってはGoogle Docsで十分といえ、さらにGoogle Docsの方が優れている点も多い。その違いを表で表してみる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
      <tr>
          <th>項目</th>
          <th>Microsoft Office</th>
          <th>Google Office</th>
      </tr>
  </thead>
  <tbody>
      <tr>
          <td>共同編集</td>
          <td>△ 共同編集には不向き</td>
          <td>〇 リアルタイムで複数人編集には強み</td>
      </tr>
      <tr>
          <td>オフライン対応</td>
          <td>〇 ローカル環境で完全に機能</td>
          <td>△ 基本的にオンライン専用</td>
      </tr>
      <tr>
          <td>高度な編集機能</td>
          <td>〇 豊富な書式設定や編集ツールが充実</td>
          <td>△ 書式やレイアウト設定のオプションは限定的</td>
      </tr>
      <tr>
          <td>ファイル互換性</td>
          <td>〇 Word形式はビジネス標準</td>
          <td>× Word形式にエクスポート可能だが、レイアウト崩れは多い</td>
      </tr>
      <tr>
          <td>価格</td>
          <td>× 高い</td>
          <td>〇 無料で利用可能</td>
      </tr>
      <tr>
          <td>テンプレートの豊富さ</td>
          <td>〇 豊富なテンプレートで自由自在</td>
          <td>△ 限定的</td>
      </tr>
      <tr>
          <td>印刷対応</td>
          <td>〇 やりやすい</td>
          <td>△ 意図通りに印刷できないことが多い</td>
      </tr>
  </tbody>
    </BasicTable>
    <p>{`つまり、Microsoftの強みはオンラインでの手軽な編集では劣るものの、`}<strong parentName="p"><em parentName="strong">{`インターネットの無いオフライン環境で快適に使える`}</em></strong>{`こと、`}<strong parentName="p"><em parentName="strong">{`ドキュメントとして配布するときに印刷に強い`}</em></strong>{`こと、`}<strong parentName="p"><em parentName="strong">{`使用できるテンプレートやイラストなどが多くビジュアルに優れた資料作成が容易である`}</em></strong>{`ことなどが挙げられる。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`Microsoft Officeはビジネス、教育などの現場で標準的に使われている`}</em></strong>{`ので、ファイルのやり取りがあるときにMicrosoft Officeが無いと困る場合も多い。`}</p>
    <p>{`家庭用に購入したパソコンでは、`}<strong parentName="p"><em parentName="strong">{`配布できるレベルの見栄えのする文書、資料を作成したい場合や、年賀状、スライドショー動画のようなやや特殊な使い方をしたい場合はMicrosoft Officeを搭載すると良い`}</em></strong>{`だろう。あとは価格との相談である。`}</p>
    <p>{`実際使いだすと、それぞれ強みが異なるため、GoogleのOfficeを使う時もあればMicrosoftのOfficeを使う時も出てくるだろう。`}</p>
    <h3 {...{
      "id": "ではOffice互換ソフトはどうか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%A7%E3%81%AFOffice%E4%BA%92%E6%8F%9B%E3%82%BD%E3%83%95%E3%83%88%E3%81%AF%E3%81%A9%E3%81%86%E3%81%8B%EF%BC%9F",
        "aria-label": "ではOffice互換ソフトはどうか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ではOffice互換ソフトはどうか？`}</h3>
    <Image {...props} name="compati" alt="Office互換ソフト" mdxType="Image" />
    <p><a parentName="p" {...{
        "href": "http://www.openoffice.org/ja/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Open Office`}</a>{`やキングソフトの`}<a parentName="p" {...{
        "href": "https://www.kingsoft.jp/office/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`WPS Office`}</a>{`といった、マクロソフトオフィスとそっくりなソフトが別途利用可能である。これらは`}<em parentName="p">{`Office互換ソフト`}</em>{`と呼ばれている。これらは`}<strong parentName="p"><em parentName="strong">{`ローカル環境で完全に機能するという意味でMicrosoft Officeと共通`}</em></strong>{`である。`}</p>
    <p>{`オープンオフィスに関しては無料であるし、WPS Officeも数千円と安い。機能もそれほど変わらないのならば、これらをマイクロソフトオフィスの代わりとして使うことはできないだろうか？`}</p>
    <p>{`もちろん可能であるが、`}<strong parentName="p"><em parentName="strong">{`互換性の問題でレイアウトが崩れる`}</em></strong>{`、`}<strong parentName="p"><em parentName="strong">{`ネットで調べても情報が少ない`}</em></strong>{`、`}<strong parentName="p"><em parentName="strong">{`クリップアートやフォントの数が少なく意図通りのデザインがおこないにくい`}</em></strong>{`など、多くの問題を抱える。`}</p>
    <p>{`Google OfficeはMicrosoft Officeには無いクラウドでの編集メリットがあるが、これらには無いため、`}<strong parentName="p"><em parentName="strong">{`安い以外に使用するメリットは何もない`}</em></strong>{`ということは覚えておこう。`}</p>
    <h2 {...{
      "id": "Office-PersonalかOffice-Home--Businessの２択",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Office-Personal%E3%81%8BOffice-Home--Business%E3%81%AE%EF%BC%92%E6%8A%9E",
        "aria-label": "Office PersonalかOffice Home  Businessの２択 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Office PersonalかOffice Home `}{`&`}{` Businessの２択`}</h2>
    <p>{`ここからはMicrosoft Officeを搭載したいと決めたうえで、どのような選択肢があるのかについて説明していく。まずオフィス製品には個人向けとしては２種類のソフトウェアパッケージが存在する。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>パッケージ名</th>
      <th>ソフトウェア</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Office Personal</td>
      <td>Word、Excel、Outlook</td>
    </tr>
    <tr>
      <td>Office Home &amp; Business</td>
      <td>Word、Excel、Outlook、PowerPoint</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`それぞれのソフト単体で購入する事もできるが、`}<strong parentName="p"><em parentName="strong">{`単体で購入すると割高になるため通常はこの２つの組み合わせのうちのいずれかを選択して購入することになる`}</em></strong>{`。`}</p>
    <p>{`Outlookはメールを管理する、あるいはスケジュールを管理するためのソフトである。グーグルのGmail、YahooのYahooメールなど複数からのメールを一つのメールソフトで閲覧できて便利である。`}</p>
    <p>{`もっとも大抵の人はGmailかYahoo、Microsoftのいずれかのメールサービスをメインとして一つ使うケースがほとんどと思われるため、あまりOutlookは必要とされないであろうが。`}</p>
    <p>{`パッケージの違いはPowerPointの有無だけである。前述のような`}<strong parentName="p"><em parentName="strong">{`スライドショーや旅行のしおりを作りたい人や、学生及び、社会人でプレゼンテーションを行う人ならばOffice Home `}{`&`}{` Businessを選ぼう`}</em></strong>{`。`}</p>
    <h2 {...{
      "id": "OEM版、オンラインコード版、Microsoft365版などのどれを選ぶべきか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#OEM%E7%89%88%E3%80%81%E3%82%AA%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%82%B3%E3%83%BC%E3%83%89%E7%89%88%E3%80%81Microsoft365%E7%89%88%E3%81%AA%E3%81%A9%E3%81%AE%E3%81%A9%E3%82%8C%E3%82%92%E9%81%B8%E3%81%B6%E3%81%B9%E3%81%8D%E3%81%8B",
        "aria-label": "OEM版、オンラインコード版、Microsoft365版などのどれを選ぶべきか permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OEM版、オンラインコード版、Microsoft365版などのどれを選ぶべきか`}</h2>
    <Image {...props} name="wondering" alt="Officeでどれを選ぶべきか" mdxType="Image" />
    <p>{`オフィス製品をパソコンに入れようと思ったとしても必ずしもパソコンの購入と同時にオフィスを入れておく必要はない。`}</p>
    <p>{`オフィス製品にはその購入形態によって`}<em parentName="p">{`OEM版`}</em>{`、`}<em parentName="p">{`オンラインコード版`}</em>{`、`}<em parentName="p">{`パッケージ版`}</em>{`、`}<em parentName="p">{`Microsoft365版`}</em>{`という種類が存在する。その4種類の特徴を説明しつつ、どういった人におすすめかについても述べていこう。下記の図を参考にしつつ話を進める。`}</p>
    <BasicTable className="center" mdxType="BasicTable">
  <thead>
    <tr>
      <th></th>
      <th>OEM板</th>
      <th>オンラインコード版</th>
      <th>パッケージ版</th>
      <th>Microsoft365</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>適用範囲</td>
      <td>１台限り</td>
      <td>２台まで</td>
      <td>２台まで</td>
      <td>無制限</td>
    </tr>
    <tr>
      <td>料金</td>
      <td>25000円程度</td>
      <td>35000円程度</td>
      <td>40000円程度</td>
      <td>月額1500円程度</td>
    </tr>
    <tr>
      <td>備考</td>
      <td align="left">購入時のパソコン以外では使用できない</td>
      <td align="left">ライセンスの他パソコンへの移行が可能</td>
      <td align="left">ライセンスの他パソコンへの移行が可能</td>
      <td align="left">Skype月60分、クラウドストレージ1TBが無料で付随</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "OEM版",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#OEM%E7%89%88",
        "aria-label": "OEM版 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OEM版`}</h3>
    <p>{`パソコンを購入した時にソフトとして一緒に付属しているものがOEM版である。OEM(Original Equipment Manufacturing)は製造の専門用語のため説明しないが、パソコンに付随して売られるソフトとここでは考えておけば良い。`}</p>
    <p>{`購入したパソコンでしか使うことができず、したがってパソコンの買い替え時も他のパソコンに移行することはできないが、`}<strong parentName="p">{`パソコンとのセット価格で購入できるため`}<em parentName="strong">{`パッケージ版と比べると割安`}</em></strong>{`である。`}</p>
    <p><strong parentName="p">{`自分用のパソコンのうち、一台のみにオフィスを入れれば十分である人はOEM版がお勧め`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "オンラインコードパッケージ版",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%82%B3%E3%83%BC%E3%83%89%E3%83%91%E3%83%83%E3%82%B1%E3%83%BC%E3%82%B8%E7%89%88",
        "aria-label": "オンラインコードパッケージ版 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`オンラインコード/パッケージ版`}</h3>
    <p>{`パソコンの付属品としてではなく、ソフトウェア単体として販売されているものがオンラインコード/パッケージ版である。使用期限がないため、２つまとめて`}<strong parentName="p"><em parentName="strong">{`永続版`}</em></strong>{`とも呼ばれる`}</p>
    <p>{`オンラインコード版とパッケージ版の違いは、物理的なDVDからOfficeをインストールするか、オンラインコードからインターネット経由でOfficeをインストールするかの違いだけであり、`}<strong parentName="p"><em parentName="strong">{`オンラインコード版の方がお得`}</em></strong>{`である。`}</p>
    <p>{`値段はOEM版よりも高価であるが、２台のパソコンにインストールできる（２台分のライセンスがある）。２台に既にインストールされている場合は、片方のパソコンからオフィス製品のアンインストールを行う事で他のパソコンにライセンスを移行することもできる。`}</p>
    <p>{`パソコンが故障してアンインストールできない状況でもサポートセンターへ連絡することによってライセンスの移行作業は行えるので問題ではない。`}</p>
    <p>{`要するに`}<strong parentName="p"><em parentName="strong">{`２台同時まではオフィス製品をインストールする事ができる`}</em></strong>{`。`}</p>
    <p>{`因みにライセンスの認証を行わなくとも、オフィス製品のインストールは可能である。しかしその認証を行わないと１ヶ月程度で機能制限が掛かってしまい、データの保存やファイルの編集ができなくなってしまう。`}</p>
    <h3 {...{
      "id": "Microsoft365版",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Microsoft365%E7%89%88",
        "aria-label": "Microsoft365版 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Microsoft365版`}</h3>
    <p>{`Microsoft365版(具体的にはMicrosoft365 Personal)は`}<strong parentName="p">{`月額課金または年額課金型の製品`}</strong>{`である。`}</p>
    <p>{`月単位で契約できるため、必要な時だけお金を払えば良い。また`}<strong parentName="p"><em parentName="strong">{`インストール数は無制限、同時に最大5台のデバイスでサインイン可能`}</em></strong>{`である。同時に５台も使う人は稀なため、ほぼ制約は無いと言える。いずれもインターネットからオフィス製品をダウンロードして使用を即座に開始する事ができる。`}</p>
    <p>{`常に最新のソフトを使うことができ、Officeの新しいバージョンが出た折にはインストールが可能であることもメリットである。AIのサポートなどもあり、生産性を求めるならば最善かもしれない。`}</p>
    <p>{`更に高価な付属サービスとして、One Driveというクラウドストレージを１TBもらう事ができ、インターネット上で多くのファイルを保存しておく事ができる。もっともMicrosoft365の解約を行うと１TBのストレージは２５GB程度までしか使えなくなるため、こまめに使用する人はこの点に関しては注意が必要である。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`スカイプが毎月60分間無料`}</em></strong>{`となり、無料範囲には固定電話や携帯電話が含まれるため、電話料金の大幅な節約が可能となる。30秒20円が固定電話の一般的な価格のため60分だと`}<strong parentName="p">{`最大で2400円の節約`}</strong>{`となる。また海外の固定電話も可能なため、この場合は更にお得である。`}</p>
    <p><strong parentName="p">{`オフィスを毎月使わない人、`}<em parentName="strong">{`目安としては年に3か月間以下の使用期間の人`}</em></strong>{`は必要な月だけ課金すれば良いためMicrosoft365が向いている。`}</p>
    <p>{`また`}<strong parentName="p">{`複数デバイスで頻繁にオフィスを使用する人`}</strong>{`も、常に最新のソフトウェアを使え、多くの端末でどこでも使う事ができるため毎月課金する価値があると言える。`}</p>
    <h3 {...{
      "id": "一般的にどれが良いかを考察",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%B8%80%E8%88%AC%E7%9A%84%E3%81%AB%E3%81%A9%E3%82%8C%E3%81%8C%E8%89%AF%E3%81%84%E3%81%8B%E3%82%92%E8%80%83%E5%AF%9F",
        "aria-label": "一般的にどれが良いかを考察 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`一般的にどれが良いかを考察`}</h3>
    <Image {...props} name="soneki" alt="Officeの損益分岐" mdxType="Image" />
    <p>{`OEM版以外には複数台インストールやスカイプなど、様々な付帯サービスがあるにせよ、価格的には高価である。`}</p>
    <p>{`図のように損益分岐を確認してみたが、Office 365を1年半程度使うと、OEMにかかるコストを上回ってしまい、`}<strong parentName="p"><em parentName="strong">{`５年365を使い続けると10万円近くOfficeに金を払うことになる`}</em></strong>{`。`}</p>
    <p>{`またパッケージ版もインストールしたいパソコンが購入時に２台無い場合にはあまりおすすめできない。何故なら次に新しいパソコンを購入するまでにバージョンアップしたOffice製品が発売される可能性も高いからである。`}</p>
    <p>{`こう考えると、`}<strong parentName="p"><em parentName="strong">{`一般的な家庭用パソコンの購入者にとってはOEMが最も良いケースが多いのではないかと思われる。よってパソコン購入時に迷ったらまずは付けておくことをおすすめしたい`}</em></strong>{`。インストールもしなくて良いので導入も楽である。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`オフィス製品には複数の使い道があり、パッケージも数種類ある。オフィス製品が必要かどうか人によって違い、答えることはできないが、これらの情報を基にして判断してほしい。製品情報については`}<a parentName="p" {...{
        "href": "https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=791390.19&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`マイクロソフトオフィス`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=791390.19&type=3&subid=0",
        "alt": "''"
      }}></img>{`のホームページを参照。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      